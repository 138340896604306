


import { Component, Prop, Vue } from 'vue-property-decorator';
import { TTextChat } from '@/_types/text-chats/text-chat.type';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import textChatsApi, {
  TCreateEditTextChatQueryParams,
  TCreateTextChatParams,
  TEditTextChatParams
} from '@/_api/text-chats.api';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';
import { TFile } from '@/_types/file.type';
import { CHAT_GROUP_PREFIX } from '@/_providers/ew.discovery-service.provider';

@Component({
  components: {
    FormFileUploader,
    IconSquareEdit,
    IconSquareDelete,
    IconPlus,
  }
})
export default class CabinetChatsEditForm extends Vue {

  public chatForm: TCreateEditTextChatQueryParams = {
    name: '',
    internal_name: '', // N.B. has to be a-zA-Z0-9_  max length 32
    sorting: null,
    description: '',
    photo_url: '',
    is_hidden: true,
  }

  public isChatImageLoading: boolean = false;
  public chatImageError: string = '';
  public chatImageFile: TFile = {
    url: '',
    filename: '',
  };

  @Prop({ type: Object, default: null })
  public readonly editingChatEntity: TTextChat;

  public mounted(): void {
    this.initEditingEntity();
  }

  private initEditingEntity(): void {
    if (this.editingChatEntity && this.editingChatEntity.id) {
      const newObj = {...this.editingChatEntity};
      delete newObj.id;
      this.chatForm = {...newObj};
      if (this.chatForm.photo_url) {
        this.chatImageFile.url = this.chatForm.photo_url;
      }
    }
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isEditMode(): boolean {
    if (this.editingChatEntity && this.editingChatEntity.id) {
      return true;
    }
    return false;
  }

  public get popupTitle(): string {
    return (this.isEditMode ? this.$t('textChats.editChat') : this.$t('textChats.createChat')) as string;
  }

  private onSubmit(): void {

    // TODO: show error on required field «Name»
    if (this.isSubmitDisabled) {
      return;
    }

    if (this.isEditMode) {
      this.editGivenChat();
    } else {
      this.createNewChat();
    }

  }

  private async createNewChat(): Promise<void> {

    this.chatForm.internal_name = this.generateInternalName();

    const payload: TCreateTextChatParams = {
      eventId: this.eventId,
      data: this.chatForm
    };

    await textChatsApi.createTextChat(payload);
    // TODO: error handling

    this.$emit('success');
  }

  private async editGivenChat(): Promise<void> {

    const payload: TEditTextChatParams = {
      eventId: this.eventId,
      chatId: this.editingChatEntity.id,
      data: this.chatForm
    };

    await textChatsApi.editTextChat(payload);
    // TODO: error handling

    this.$emit('success');
  }

  public get isSubmitDisabled(): boolean {
    return !this.chatForm.name;
  }

  private generateInternalName(): string {
    const randomPart: string = Math.floor(Math.random() * 1000).toFixed(0).padStart(4, '0');
    const timestamp: string = (new Date()).getTime().toFixed(0);

    return CHAT_GROUP_PREFIX + ([this.eventId, timestamp, randomPart].join('_'));
  }

  private setIsChatImageLoading(value: TFile): void {
    if (value && value.url === '') {
      this.isChatImageLoading = true;
      return;
    } else if (value.url) {
      this.isChatImageLoading = false;
      this.chatForm.photo_url = value.url;
    }
    this.isChatImageLoading = false;
  }

  private setChatImageError(err: string): void {
    this.chatImageError = err || '';
    setTimeout(() => {
      this.chatImageError = '';
      this.isChatImageLoading = false;
    }, 3000);
  }

  private deleteChatImage(): void {
    this.chatForm.photo_url = '';
    this.chatImageFile.url = '';
  }

  private onChatImageInput(value: TFile): void {
    this.chatForm.photo_url = value.url;
    this.isChatImageLoading = false;
  }

}
