<template>
  <svg width="90" height="90" viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M77.6924 39.4738C77.6917 38.6094 77.0629 37.8734 76.2091 37.7383L71.8432 37.0466L69.5948 31.6242L72.1902 28.046C72.6979 27.3464 72.6212 26.3816 72.0099 25.7706L64.2096 17.9757C63.598 17.3645 62.6328 17.2889 61.9341 17.7969L58.3592 20.3938L52.9328 18.1485L52.2382 13.7849C52.1023 12.9317 51.3663 12.3036 50.5021 12.3036H45C44.0293 12.3036 43.2422 13.0906 43.2422 14.0614C43.2422 15.0323 44.0293 15.8192 45 15.8192H49.0022L49.6157 19.6731C49.7118 20.2769 50.1147 20.7872 50.6797 21.0209L57.9243 24.0185C58.4893 24.2522 59.1351 24.1757 59.6294 23.8164L62.7863 21.5233L68.4635 27.1967L66.1715 30.3567C65.8125 30.8515 65.7364 31.4973 65.9706 32.0621L68.9729 39.3022C69.207 39.8668 69.7177 40.2693 70.3217 40.365L74.1773 40.9758L74.1806 49.002L70.3275 49.6155C69.7237 49.7116 69.2134 50.1145 68.9796 50.6795L65.9818 57.9241C65.748 58.4889 65.8245 59.1347 66.1838 59.6292L68.477 62.7859L62.8038 68.4631L59.6437 66.1712C59.1488 65.8123 58.503 65.7362 57.9384 65.9703L50.6983 68.9725C50.1335 69.2066 49.731 69.7174 49.6354 70.3213L49.0243 74.1784H45C44.0293 74.1784 43.2422 74.9656 43.2422 75.9362C43.2422 76.9069 44.0293 77.6941 45 77.6941H50.5257C51.3904 77.6941 52.1265 77.0653 52.2619 76.2112L52.9538 71.8428L58.3763 69.5944L61.9545 72.1898C62.6539 72.6973 63.6189 72.621 64.2298 72.0095L72.0246 64.2093C72.6358 63.5978 72.7114 62.6329 72.2036 61.9339L69.6066 58.359L71.852 52.9326L76.2154 52.2379C77.069 52.1021 77.6973 51.3655 77.6969 50.5012L77.6924 39.4738Z"
      fill="#00B6F8"/>
    <path
      d="M44.9995 29.9829C44.0288 29.9829 43.2417 30.7699 43.2417 31.7407C43.2417 32.7115 44.0288 33.4985 44.9995 33.4985C51.3415 33.4985 56.5012 38.6582 56.5012 45.0002C56.5012 49.2809 54.1467 53.182 50.3565 55.1811C49.4978 55.6339 49.1687 56.6972 49.6217 57.5559C49.9365 58.1531 50.5467 58.4941 51.1781 58.4941C51.4544 58.4941 51.7351 58.4287 51.9965 58.2907C56.9437 55.6814 60.0169 50.5888 60.0169 45.0002C60.0169 36.7197 53.2802 29.9829 44.9995 29.9829Z"
      fill="#00B6F8"/>
    <path
      d="M10.1953 78.0469H1.75781C0.787148 78.0469 0 78.834 0 79.8047V88.2422C0 89.2129 0.787148 90 1.75781 90H10.1953C11.166 90 11.9531 89.2129 11.9531 88.2422V79.8047C11.9531 78.834 11.166 78.0469 10.1953 78.0469ZM8.4375 86.4844H3.51562V81.5625H8.4375V86.4844Z"
      fill="#00B6F8"/>
    <path
      d="M88.2422 78.0469H79.8047C78.834 78.0469 78.0469 78.834 78.0469 79.8047V88.2422C78.0469 89.2129 78.834 90 79.8047 90H88.2422C89.2129 90 90 89.2129 90 88.2422V79.8047C90 78.834 89.2129 78.0469 88.2422 78.0469ZM86.4844 86.4844H81.5625V81.5625H86.4844V86.4844Z"
      fill="#00B6F8"/>
    <path
      d="M10.1953 0H1.75781C0.787148 0 0 0.786973 0 1.75781V10.1953C0 11.1662 0.787148 11.9531 1.75781 11.9531H10.1953C11.166 11.9531 11.9531 11.1662 11.9531 10.1953V1.75781C11.9531 0.786973 11.166 0 10.1953 0ZM8.4375 8.4375H3.51562V3.51562H8.4375V8.4375Z"
      fill="#00B6F8"/>
    <path
      d="M88.2422 0H79.8047C78.834 0 78.0469 0.786973 78.0469 1.75781V10.1953C78.0469 11.1662 78.834 11.9531 79.8047 11.9531H88.2422C89.2129 11.9531 90 11.1662 90 10.1953V1.75781C90 0.786973 89.2129 0 88.2422 0ZM86.4844 8.4375H81.5625V3.51562H86.4844V8.4375Z"
      fill="#00B6F8"/>
    <path
      d="M84.0234 68.1277C83.0528 68.1277 82.2656 68.9148 82.2656 69.8855V71.6433C82.2656 72.6139 83.0528 73.4011 84.0234 73.4011C84.9941 73.4011 85.7812 72.6139 85.7812 71.6433V69.8855C85.7812 68.9148 84.9941 68.1277 84.0234 68.1277Z"
      fill="#00B6F8"/>
    <path
      d="M84.0234 59.3386C83.0528 59.3386 82.2656 60.1258 82.2656 61.0964V62.8542C82.2656 63.8249 83.0528 64.612 84.0234 64.612C84.9941 64.612 85.7812 63.8249 85.7812 62.8542V61.0964C85.7812 60.1258 84.9941 59.3386 84.0234 59.3386Z"
      fill="#00B6F8"/>
    <path
      d="M84.0234 16.8793C83.0528 16.8793 82.2656 17.6663 82.2656 18.6371V18.9089C82.2656 19.8797 83.0528 20.6667 84.0234 20.6667C84.9941 20.6667 85.7812 19.8797 85.7812 18.9089V18.6371C85.7812 17.6663 84.9941 16.8793 84.0234 16.8793Z"
      fill="#00B6F8"/>
    <path
      d="M84.0234 50.5496C83.0528 50.5496 82.2656 51.3367 82.2656 52.3074V54.0652C82.2656 55.0358 83.0528 55.823 84.0234 55.823C84.9941 55.823 85.7812 55.0358 85.7812 54.0652V52.3074C85.7812 51.3367 84.9941 50.5496 84.0234 50.5496Z"
      fill="#00B6F8"/>
    <path
      d="M84.0234 24.1824C83.0528 24.1824 82.2656 24.9694 82.2656 25.9402V27.6981C82.2656 28.6689 83.0528 29.4559 84.0234 29.4559C84.9941 29.4559 85.7812 28.6689 85.7812 27.6981V25.9402C85.7812 24.9694 84.9941 24.1824 84.0234 24.1824Z"
      fill="#00B6F8"/>
    <path
      d="M84.0234 41.7606C83.0528 41.7606 82.2656 42.5475 82.2656 43.5184V45.2762C82.2656 46.2468 83.0528 47.034 84.0234 47.034C84.9941 47.034 85.7812 46.2468 85.7812 45.2762V43.5184C85.7812 42.5475 84.9941 41.7606 84.0234 41.7606Z"
      fill="#00B6F8"/>
    <path
      d="M84.0234 32.9715C83.0528 32.9715 82.2656 33.7585 82.2656 34.7293V36.4871C82.2656 37.4579 83.0528 38.2449 84.0234 38.2449C84.9941 38.2449 85.7812 37.4579 85.7812 36.4871V34.7293C85.7812 33.7585 84.9941 32.9715 84.0234 32.9715Z"
      fill="#00B6F8"/>
    <path
      d="M5.97656 41.7606C5.0059 41.7606 4.21875 42.5475 4.21875 43.5184V45.2762C4.21875 46.2468 5.0059 47.034 5.97656 47.034C6.94723 47.034 7.73438 46.2468 7.73438 45.2762V43.5184C7.73438 42.5475 6.94723 41.7606 5.97656 41.7606Z"
      fill="#00B6F8"/>
    <path
      d="M5.97656 50.5496C5.0059 50.5496 4.21875 51.3367 4.21875 52.3074V54.0652C4.21875 55.0358 5.0059 55.823 5.97656 55.823C6.94723 55.823 7.73438 55.0358 7.73438 54.0652V52.3074C7.73438 51.3367 6.94723 50.5496 5.97656 50.5496Z"
      fill="#00B6F8"/>
    <path
      d="M5.97656 16.8793C5.0059 16.8793 4.21875 17.6663 4.21875 18.6371V18.9089C4.21875 19.8797 5.0059 20.6667 5.97656 20.6667C6.94723 20.6667 7.73438 19.8797 7.73438 18.9089V18.6371C7.73438 17.6663 6.94723 16.8793 5.97656 16.8793Z"
      fill="#00B6F8"/>
    <path
      d="M5.97656 32.9715C5.0059 32.9715 4.21875 33.7585 4.21875 34.7293V36.4871C4.21875 37.4579 5.0059 38.2449 5.97656 38.2449C6.94723 38.2449 7.73438 37.4579 7.73438 36.4871V34.7293C7.73438 33.7585 6.94723 32.9715 5.97656 32.9715Z"
      fill="#00B6F8"/>
    <path
      d="M5.97656 59.3386C5.0059 59.3386 4.21875 60.1258 4.21875 61.0964V62.8542C4.21875 63.8249 5.0059 64.612 5.97656 64.612C6.94723 64.612 7.73438 63.8249 7.73438 62.8542V61.0964C7.73438 60.1258 6.94723 59.3386 5.97656 59.3386Z"
      fill="#00B6F8"/>
    <path
      d="M5.97656 24.1824C5.0059 24.1824 4.21875 24.9694 4.21875 25.9402V27.6981C4.21875 28.6689 5.0059 29.4559 5.97656 29.4559C6.94723 29.4559 7.73438 28.6689 7.73438 27.6981V25.9402C7.73438 24.9694 6.94723 24.1824 5.97656 24.1824Z"
      fill="#00B6F8"/>
    <path
      d="M5.97656 68.1277C5.0059 68.1277 4.21875 68.9148 4.21875 69.8855V71.6433C4.21875 72.6139 5.0059 73.4011 5.97656 73.4011C6.94723 73.4011 7.73438 72.6139 7.73438 71.6433V69.8855C7.73438 68.9148 6.94723 68.1277 5.97656 68.1277Z"
      fill="#00B6F8"/>
    <path
      d="M55.4111 82.406H53.6533C52.6827 82.406 51.8955 83.1932 51.8955 84.1638C51.8955 85.1345 52.6827 85.9217 53.6533 85.9217H55.4111C56.3818 85.9217 57.1689 85.1345 57.1689 84.1638C57.1689 83.1932 56.3818 82.406 55.4111 82.406Z"
      fill="#00B6F8"/>
    <path
      d="M37.833 82.406H36.0752C35.1045 82.406 34.3174 83.1932 34.3174 84.1638C34.3174 85.1345 35.1045 85.9217 36.0752 85.9217H37.833C38.8037 85.9217 39.5908 85.1345 39.5908 84.1638C39.5908 83.1932 38.8037 82.406 37.833 82.406Z"
      fill="#00B6F8"/>
    <path
      d="M46.6221 82.406H44.8643C43.8936 82.406 43.1064 83.1932 43.1064 84.1638C43.1064 85.1345 43.8936 85.9217 44.8643 85.9217H46.6221C47.5927 85.9217 48.3799 85.1345 48.3799 84.1638C48.3799 83.1932 47.5927 82.406 46.6221 82.406Z"
      fill="#00B6F8"/>
    <path
      d="M29.0439 82.406H27.2861C26.3155 82.406 25.5283 83.1932 25.5283 84.1638C25.5283 85.1345 26.3155 85.9217 27.2861 85.9217H29.0439C30.0146 85.9217 30.8018 85.1345 30.8018 84.1638C30.8018 83.1932 30.0146 82.406 29.0439 82.406Z"
      fill="#00B6F8"/>
    <path
      d="M64.2002 82.406H62.4424C61.4717 82.406 60.6846 83.1932 60.6846 84.1638C60.6846 85.1345 61.4717 85.9217 62.4424 85.9217H64.2002C65.1709 85.9217 65.958 85.1345 65.958 84.1638C65.958 83.1932 65.1709 82.406 64.2002 82.406Z"
      fill="#00B6F8"/>
    <path
      d="M20.2549 82.406H18.4971C17.5264 82.406 16.7393 83.1932 16.7393 84.1638C16.7393 85.1345 17.5264 85.9217 18.4971 85.9217H20.2549C21.2255 85.9217 22.0127 85.1345 22.0127 84.1638C22.0127 83.1932 21.2255 82.406 20.2549 82.406Z"
      fill="#00B6F8"/>
    <path
      d="M71.5025 82.406H71.231C70.2603 82.406 69.4731 83.1932 69.4731 84.1638C69.4731 85.1345 70.2603 85.9217 71.231 85.9217H71.5025C72.4732 85.9217 73.2604 85.1345 73.2604 84.1638C73.2604 83.1932 72.4734 82.406 71.5025 82.406Z"
      fill="#00B6F8"/>
    <path
      d="M55.4111 4.35899H53.6533C52.6827 4.35899 51.8955 5.14597 51.8955 6.11681C51.8955 7.08765 52.6825 7.87462 53.6533 7.87462H55.4111C56.3818 7.87462 57.1689 7.08765 57.1689 6.11681C57.1689 5.14597 56.3818 4.35899 55.4111 4.35899Z"
      fill="#00B6F8"/>
    <path
      d="M46.6221 4.35899H44.8643C43.8936 4.35899 43.1064 5.14597 43.1064 6.11681C43.1064 7.08765 43.8934 7.87462 44.8643 7.87462H46.6221C47.5927 7.87462 48.3799 7.08765 48.3799 6.11681C48.3799 5.14597 47.5927 4.35899 46.6221 4.35899Z"
      fill="#00B6F8"/>
    <path
      d="M71.503 4.35899H71.2314C70.2608 4.35899 69.4736 5.14597 69.4736 6.11681C69.4736 7.08765 70.2606 7.87462 71.2314 7.87462H71.503C72.4737 7.87462 73.2608 7.08765 73.2608 6.11681C73.2608 5.14597 72.4737 4.35899 71.503 4.35899Z"
      fill="#00B6F8"/>
    <path
      d="M64.2002 4.35899H62.4424C61.4717 4.35899 60.6846 5.14597 60.6846 6.11681C60.6846 7.08765 61.4715 7.87462 62.4424 7.87462H64.2002C65.1709 7.87462 65.958 7.08765 65.958 6.11681C65.958 5.14597 65.1709 4.35899 64.2002 4.35899Z"
      fill="#00B6F8"/>
    <path
      d="M37.833 4.35899H36.0752C35.1045 4.35899 34.3174 5.14597 34.3174 6.11681C34.3174 7.08765 35.1044 7.87462 36.0752 7.87462H37.833C38.8037 7.87462 39.5908 7.08765 39.5908 6.11681C39.5908 5.14597 38.8037 4.35899 37.833 4.35899Z"
      fill="#00B6F8"/>
    <path
      d="M29.0439 4.35899H27.2861C26.3155 4.35899 25.5283 5.14597 25.5283 6.11681C25.5283 7.08765 26.3153 7.87462 27.2861 7.87462H29.0439C30.0146 7.87462 30.8018 7.08765 30.8018 6.11681C30.8018 5.14597 30.0146 4.35899 29.0439 4.35899Z"
      fill="#00B6F8"/>
    <path
      d="M20.2549 4.35899H18.4971C17.5264 4.35899 16.7393 5.14597 16.7393 6.11681C16.7393 7.08765 17.5264 7.87462 18.4971 7.87462H20.2549C21.2255 7.87462 22.0127 7.08765 22.0127 6.11681C22.0127 5.14597 21.2255 4.35899 20.2549 4.35899Z"
      fill="#00B6F8"/>
    <path
      d="M27.9845 38.308C27.6575 37.9811 27.204 37.793 26.7417 37.793C26.2776 37.793 25.8259 37.9811 25.4989 38.308C25.1702 38.635 24.9839 39.0867 24.9839 39.5508C24.9839 40.0131 25.1702 40.4666 25.4989 40.7936C25.8259 41.1205 26.2776 41.3086 26.7417 41.3086C27.204 41.3086 27.6575 41.1205 27.9845 40.7936C28.3112 40.4666 28.4995 40.0131 28.4995 39.5508C28.4995 39.0885 28.3114 38.635 27.9845 38.308Z"
      fill="#00B6F8"/>
    <path
      d="M35.9824 11.4554H28.7502C26.6734 11.4554 24.9834 13.1452 24.9834 15.2222V47.0114C24.9834 47.9823 25.7705 48.7692 26.7412 48.7692C27.7119 48.7692 28.499 47.9823 28.499 47.0114V25.8193H36.2334V61.3215H28.499V46.4063C28.499 45.4356 27.7119 44.6485 26.7412 44.6485C25.7705 44.6485 24.9834 45.4356 24.9834 46.4063V63.0793C24.9834 63.3219 25.0335 63.5617 25.1309 63.7839L30.7559 76.641C31.0357 77.2811 31.6678 77.6943 32.3662 77.6943C33.0646 77.6943 33.6967 77.2809 33.9765 76.641L39.6015 63.7839C39.6989 63.5617 39.749 63.3219 39.749 63.0793V15.2222C39.7492 13.1452 38.0594 11.4554 35.9824 11.4554ZM32.3664 71.5508L29.4291 64.8372H35.3035L32.3664 71.5508ZM36.2337 22.3037H28.4992V15.2222C28.4992 15.0837 28.6119 14.971 28.7504 14.971H35.9826C36.1211 14.971 36.2337 15.0837 36.2337 15.2222V22.3037Z"
      fill="#00B6F8"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconNoDiscussions = Vue.extend({
  name: 'icon-no-discussions',
});
export default IconNoDiscussions;
</script>
